<!-- 列表展示 -->
<template>
  <div>
    <!--主商品信息-->
    <div class="p v2-g-flex-row v2-g-flex-space-between">
      <div class="sel" v-if="isShopCard && !isShopCard_Promotion">
        <input
          type="checkbox"
          v-model="product.isChecked"
          @click.stop=""
          @change="shopCardCheck()"
        />
        <span style="visibility: hidden">占位</span>
      </div>

      <div class="product v2-g-flex-row">
        <div class="pic" @click.stop="jumpdetail">
          <div class="pic-center">
            <img :src="((product.isWatch && product.isWatch === true && product.learningUrl) ? product.learningUrl : product.mainImageUrl)" />
          </div>
          <!-- 购买次数  -->
          <div class="purchaseTime-wrapper" v-if="product.buycs && product.buycs > 0">
              <div :class="['purchaseTimes', product.buysNum90day ? 'arrive_tip_color' : '']">买过<span>{{ product.buycs }}</span>次</div>
          </div>
          <div class="purchaseTime-wrapper" v-else-if="product.goodsSaleQty90BillNoCnt">
            <div class="purchaseTimes">{{ product.goodsSaleQty90BillNoCnt }}人次购买</div>
          </div>
          <!-- 无货，失效 -->
          <div
            class="center"
            v-if="product.availableNum <= 0 || !product.isInvalid">
            <div v-if="product.availableNum <= 0" class="bj">已售罄</div>
            <div v-else-if="!product.isInvalid" class="bj">失效</div>
          </div>
          <!-- 视频红包-->
          <div class="center" v-if="product.videoId" @click.stop="isstate = true">
            <div class="center-video"></div>
            <div class="center-text" v-if="!product.isView">观看视频领{{ product.redbagMoney }}元</div>
          </div>
          <!-- 无货 -->
          <div class="center" v-if="product.kcName == '库存不足'">
            <div class="noavaible">库存不足</div>
          </div>
          <!-- 返利 0差价标签 -->
          <div
            class="tag-bottom-right"
            v-if="
            product.isZeroPriceDifference || product.productAttribute == '1'
          "
            :class="[
            product.isZeroPriceDifference ? 'lcj' : '',
            product.productAttribute == '1' ? 'fy' : ''
          ]"
          >
            <div v-if="product.isZeroPriceDifference" class="c1">0</div>
            <div v-if="product.isZeroPriceDifference" class="c">差价</div>
            <div v-else-if="product.productAttribute == '1'" class="c">返利</div>
          </div>
          <!-- 缺货，库存不足 -->
          <div
            class="bottom"
            v-if="product.availableNum > 0 && product.availableNum < 100"
          >
            <span v-if="product.isWillNotHave == 1"
            >仅剩{{ product.availableNum }}{{ product.sellUnit }},即将缺货</span>
            <span v-else
            >仅剩{{ product.availableNum }}{{ product.sellUnit }}</span>
          </div>
          <!-- 对标原研 -->
          <div class="center" v-if="product.productPatent=='1'">
            <div class="db_icon">
              <img src="https://obs.pujian.com/frontend/app/components/db_icon.png" />
            </div>
          </div>
          <div :class="['rgPs', product.productInfoVO.isLearning && product.productInfoVO.isLearning === 1 && product.areaExclusiveProductCategory === 0 ? 'xsPs' : '']" v-if="product.areaExclusiveProductCategory === 1 || (product.productInfoVO.isLearning && product.productInfoVO.isLearning === 1)">
            {{ product.areaExclusiveProductCategory === 1 ? '区域独家' : product.productInfoVO.isLearning && product.productInfoVO.isLearning === 1 ? '学术' : '' }}
          </div>
        </div>
        <div class="attr v2-g-flex-column">
          <div
            class="product-name"
            :title="product.productName + '【' + product.specifications + '】'">
            <span
              v-if="product.productNameHighlight"
              v-html="product.productNameHighlight"
            ></span>
            <span v-else> {{ product.productName }}</span>
            <span class="guige">【{{ product.specifications }}】</span>
          </div>

          <productLabels :itemObject="product"/>

          <!--商品的活动标签-->
          <ProductActivityTags ref="productActivityTagsRef" :key="product.productId" :product="product" />

          <div class="company margin11">
          <span
            v-if="product.produceFactoryHighlight"
            v-html="product.produceFactoryHighlight"
          ></span>
            <span v-else>{{ product.produceFactory }}</span>
          </div>
          <div class="yxq">
            <span>效期:{{ product.nearEffectivePeriod }}</span>
          </div>
          <div class="thsj">
            <span v-if="product.lastReturnTime">最后退货时间:{{ product.lastReturnTime }}</span>
          </div>
          <div class="syz" v-if="product.productInstructionHighlight">
            适应症:<span
            v-html="product.productInstructionHighlight"
            :title="product.productInstruction"
          ></span>
          </div>

          <lowestPriceForSimilar ref="lowestPriceForSimilarRef" :product="product" :isShowSimilar="isShowSimilar" @showSimilar="showSimilar" />
          <img class="isControl" v-if="product.factoryControl === 1" src="https://obs.pujian.com/frontend/app/controlSell/controlSell.png" />
        </div>
      </div>

      <div class="price">
        <div class="act-tip" v-if="product.actTip && product.actTip.length > 0">
          {{ product.actTip }}
        </div>
        <span v-if="!product.preferenticePrice">定价中</span>
        <span v-else-if="product.isActivityGood">均价￥{{product.averagePrice }}</span>
        <template v-else-if="judgmentPreferentialType()">
          <span>特价￥{{ product.activityVo.activityStrategyReduceVOS[0].activityPrice }}</span>
          <span class="del">￥{{product.preferenticePrice }}</span>
        </template>

        <span v-else>价格 ￥{{ product.preferenticePrice }}/{{ product.sellUnit }}</span>
        <div class="mly">
        <span>零售价:{{ product.suggestedPrice.toFixed(2) + "/" + product.sellUnit }}
          <template v-if="product.grossProfitRate != null">
            <span style="margin-left: 2px">毛利率{{product.grossProfitRate.toFixed(2) }}%</span>
          </template>
        </span>
        </div>
      </div>

      <div class="card-num">
        <div class="special-offer-content" v-if="product.isActivityGood">
          <div class="special-offer-item" v-if="product.superDiscountProductNum">
            <span class="special-offer-item-left">特价￥{{ product.superDiscountProductPrice }}</span>
            <span class="special-offer-item-right">×{{ product.superDiscountProductNum }}{{ product.sellUnit }}</span>
          </div>
          <div class="special-offer-item" v-if="product.fullReductionNum">
            <span class="special-offer-item-left">活动价￥{{ product.fullReductionProductPrice }}</span>
            <span class="special-offer-item-right">×{{ product.fullReductionNum }}{{ product.sellUnit }}</span>
          </div>
          <div class="special-offer-item" v-if="product.oldNum">
            <span class="special-offer-item-left">原价￥{{ Number(product.oldPriceNew).toFixed(2) }}</span>
            <span class="special-offer-item-right">×{{ product.oldNum }}{{ product.sellUnit }}</span>
          </div>
        </div>

        <div class="zbz" v-if="product.isMediumPackage">
          中袋装:(1中包装={{ product.mediumPackage }}{{ product.sellUnit }})
        </div>
        <div class="num">
          <div
            class="num-real"
            v-if="
            product.isMediumPackage == 1 &&
            product.mediumPackage &&
            product.mediumPackage > 0
          "
          >
            实际购买{{ product.mediumPackage * buyCount }}{{ product.sellUnit }}
          </div>
          <div class="num-input v2-g-flex-row">
            <a class="op" href="javascript:;" @click.stop="addCount(-1)"
            ><i class="iconfont icon-sami-select"></i></a>
            <input
              type="number"
              v-model="buyCount"
              placeholder="0"
              maxlength="4"
              ref="ballnum"
              @change="modifyCount()"
              @keyup.enter="modifyCount()"
              @blur="modifyCount()"
              :disabled="product.status == '4'"
            />
            <a class="op" href="javascript:;" @click.stop="addCount(1)"
            ><i class="iconfont icon-add-select"></i
            ></a>
          </div>
        </div>
      </div>

      <div class="t" v-if="isShopCard">
        <div class="pro" v-if="product.reducedPrice > 0">
          <span>￥{{ product.reducedPrice }}</span>
        </div>

        <div
          class="pro1"
          v-if="product.reducedPrice == 0 && product.redbagMoney > 0"
        >
          <span>￥{{ product.redbagMoney }}</span>
        </div>

        <span>￥{{ product.subtotalMoneys }}</span>
        <span v-if="product.reducedPrice > 0" class="v-line-through orign"
        >￥{{ (product.subtotalMoney + product.reducedPrice).toFixed(2) }}</span>
      </div>

      <div class="card-btn" v-if="product.factoryControl === 0">
        <span
          v-if="isShopCard"
          class="remove v2-g-flex-column link-color"
          @click.stop="shopCardDel()"
        >
          <i class="iconfont icon-ashbin1 del"></i>
          <i class="txt">删除</i>
        </span>
        <button
          v-else
          class="btn"
          style="display: flex; align-items: center"
          type="button"
          @click.stop="addCard()"
          :disabled="product.availableNum <= 0 || !product.isInvalid"
        >
          <img src="@/assets-v2/images/sy_gouwuche.png" style="width: 28px" />
          <span>加入购物车</span>
        </button>
      </div>
      <div class="isControlSale" v-else @click="lookPhone(product)">
        <div class="isControlSale_btn">查看代理联系方式</div>
      </div>
    </div>

    <!--赠品信息-->
    <div
      v-if="product.gift"
      class="p v2-g-flex-row v2-g-flex-space-between"
      :style="{
        'margin-left': !isShopCard_Promotion ? '90px' : '0',
        'width': !isShopCard_Promotion ? '896px' : '960px'
      }">
      <div class="product v2-g-flex-row" style="height: auto;">
        <div class="pic" style="margin: 0; margin-bottom: 10px;">
          <img style="width: 90px; height: 90px;" :src="product.gift.picPath" />
          <div class="product-info_giveaway">赠品</div>
        </div>
        <div class="attr v2-g-flex-column">
          <div class="product-name" style="margin: 0;">
            <span>{{ product.gift.productName }}</span>
            <span class="guige">【{{ product.gift.specifications }}】</span>
          </div>
          <div class="company margin11">{{ product.gift.produceFactory }}</div>
        </div>
      </div>

      <div class="price">￥{{ product.gift.unitPrice }}</div>

      <div class="card-num">{{ product.gift.totalNumber }}</div>

      <div class="t">￥{{ product.gift.subtotalMoney }}</div>
    </div>

    <Transition
      appear
      @before-appear="beforeEnter"
      @enter="enter"
      @after-appear="afterEnter">
      <div
        class="add-card-ball"
        v-if="showMoveDot"
        ref="ball"
        :style="{ top: top + 'px', left: left + 'px' }"
      ></div>
    </Transition>
    <VideoRedPacket :show="isstate" @close="isstate=false" :url="product.videoPlayUrl" :id="product.videoId" />
    <!--药品控制-->
    <addCarModal ref="addCarModalRef" :p_obj="p_obj"/>

    <!--过期/临期资质弹窗-->
    <qualificationsDialog ref="qualificationsDialogRef" @continueToAddPurchases="continueToAddPurchases" />
    <el-dialog :visible.sync="dl_modal" width="327px" :show-close="false">
      <supplier-ruler-pop :rulerData="dl_modal_obj" @popStatus="popStatus"></supplier-ruler-pop>
    </el-dialog>
  </div>
</template>

<style scoped lang="less">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.p {
  align-items: center;
  ::v-deep .el-dialog__header{
    padding: 0;
  }
  ::v-deep .el-dialog__body{
    padding: 0;
  }
  ::v-deep .el-dialog{
    border-radius: 20px;
  }
}

.sel {
  width: 80px;

  input[type="checkbox"] {
    width: 15px;
    height: 15px;
    margin-right: 3px;
  }
}

.price {
  width: 230px;
  font-size: 1rem;
  font-weight: bold;
  color: #e5432e;
  line-height: 16px;
  text-align: center;

  .del {
    color: #999999;
    text-decoration: line-through;
  }

  .mly {
    margin-top: 5px;
    color: #999999;
    font-size: 0.6rem;
    font-weight: 400;
  }

  .act-tip {
    font-size: 0.8rem;
    font-weight: 400;
    height: 30px;
    line-height: 30px;
    margin-bottom: 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.card-num {
  width: 150px;
  text-align: center;
}

.t {
  width: 120px;
  text-align: center;
  font-weight: 600;
  font-size: 1.3rem;
  color: #e5432e;
  .orign {
    font-size: 0.8rem;
    margin-left: 10px;
    color: #666666;
  }

  .pro {
    margin-left: 15px;
    padding-top: 20px;
    font-size: 16px;
    height: 52px;
    width: 65px;
    transform: translateY(15px) rotate(-10deg);
    background-image: url("../../assets-v2/images/shopping/bg_mj.png");
    background-size: 100%;
    background-repeat: no-repeat;
    span {
      color: #ffffff;
      display: inline-block;
      transform: rotate(20deg);
      font-weight: bold;
      margin-right: 10px;
    }
  }
  .pro1 {
    margin-left: 15px;
    padding-top: 20px;
    font-size: 16px;
    height: 52px;
    width: 65px;
    transform: translateY(15px) rotate(-10deg);
    background-image: url("../../assets-v2/images/shopping/bg_hb.png");
    background-size: 100%;
    background-repeat: no-repeat;
    span {
      color: #ffffff;
      display: inline-block;
      transform: rotate(20deg);
      font-weight: bold;
      margin-right: 10px;
    }
  }
}

.card-btn {
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    border: none;
    cursor: pointer;
    box-shadow: 7px 6px 10px 1px rgba(0, 0, 0, 0.14);

    &:active {
      transform: scale(0.98);
      box-shadow: 3px 2px 5px 1px rgba(0, 0, 0, 0.14);
    }
  }

  .remove {
    .del {
      font-size: 1.5rem;
    }

    .txt {
      font-style: normal;
    }
  }
}

.zbz,
.ns-price {
  font-size: 12px;
  font-weight: 400;
  color: #666666;
}

.act ~ .act {
  background: #fff4d7;
}

.act {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  background: #fff3f3;
  border-radius: 9px;
  .act-type {
    width: 40px;
    height: 21px;
    display: inline-block;
    color: #ffffff;
    font-size: 0.65rem;
    border-radius: 9px 0 9px 9px;
    text-align: center;
    line-height: 21px;
  }

  .act-mj {
    background: linear-gradient(0deg, #fe4b4f 0%, #fea8c1 100%);
  }

  .act-fhb {
    background: linear-gradient(0deg, #fc664c 0%, #fcb466 100%);
  }

  .act-name {
    font-size: 12px;
    padding-left: 1px;
    margin-left: 5px;
    i {
      color: #e5432e;
      font-style: normal;
    }
  }
}

.num {
  padding: 0 3px;
  margin-top: 5px;

  input {
    display: inline-block;
    width: 60px;
    height: 30px;
    border: none;
    text-align: center;
    border-left: 1px solid #dbdbdb;
    border-right: 1px solid #dbdbdb;
  }

  .num-real {
    line-height: 12px;
    font-size: 0.5rem;
    height: 12px;
    margin-bottom: 10px;
    color: #fe5257;
  }

  .num-input {
    justify-content: space-between;
    align-items: center;
    border: 1px solid #dbdbdb;
    width: 120px;
    margin: 0 auto;
  }

  .op {
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
    text-decoration: none;
    font-weight: 600;
    color: grey;
  }
}

.product {
  cursor: pointer;
  padding: 0 0 8px 0;
}

.pic {
  margin: 16px 0;
  width: 120px;
  position: relative;
  font-size: 0.85rem;
  font-weight: 400;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &:hover {
    .detail {
      display: block;
    }
  }
  .purchaseTime-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 80px;
    height: 80px;
    overflow: hidden;
    z-index: 11;
    .purchaseTimes {
      position: absolute;
      top: -50px;
      left: -50px;
      font-size: 12px;
      width: 100px;
      height: 100px;
      background-color: #e6854b;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      -webkit-transform-origin: center;
      transform-origin: center;
      color: #ffffff;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      padding-bottom: 3px;
      span{
        color: #e5432e;
      }
    }
    .arrive_tip_color{
      background: #E0FEE6;
      color: #007E15;
    }
  }
  .detail {
    display: none;
    position: absolute;
    right: auto;
    width: 450px;
    padding: 12px 12px;
    top: -10px;
    left: calc(100% + 10px);
    font-size: 0.85rem;
    font-weight: 400;
    z-index: 38;
    background: #ffffff;
    color: rgba(0, 0, 0, 0.7);
    box-shadow: 0 1px 2px 1px rgb(0 1 0 / 20%);

    .name {
      font-size: 1rem;
      text-align: left;
      font-weight: 600;
    }

    .price {
      font-size: 0.875rem;
    }

    .basic-info {
      display: flex;
      flex-wrap: wrap;
      padding: 10px 10px 10px 10px;

      li {
        display: flex;
        align-items: center;
        height: 30px;
        line-height: 30px;
        width: 100%;
        color: #666666;

        &.price {
          p {
            font-weight: 400;

            &:nth-child(2) {
              color: #e5432e;
            }
          }
        }

        p {
          &:first-child {
            width: 85px;
            text-align: left;
          }
        }

        :last-child,
        &.actli {
          height: inherit;
          line-height: inherit;
        }

        .act-item {
          margin-left: 0;
        }
      }
    }
  }

  .pic-center {
    width: 120px;
    height: 120px;
    overflow: hidden;

    img {
      margin: 0 auto;
      width: 120px;
      height: 120px;
      display: block;
    }
  }

  .tag-top-right {
    position: absolute;
    top: 0;
    right: 0;
    width: 35px;
    height: 27px;
    background: #00b0ff;
    color: #fff;
    text-align: center;
    line-height: 27px;
    background: linear-gradient(90deg, #4bb1ff 0%, #207cf5 100%);
    border-radius: 0 8px 0 8px;
  }

  .tag-bottom-right {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 43px;
    height: 42px;
    border-radius: 38px 0 4px 0;
  }

  .lcj {
    background: linear-gradient(0deg, #1b45f3 0%, #61c6fb 100%);
    color: #ffffff;
    width: 35px;
    height: 38px;

    .c {
      position: absolute;
      bottom: 1px;
      right: 2px !important;
      font-weight: 600;
      font-size: 12px;
    }

    .c1 {
      color: #fff;
      margin-top: 0;
      padding: 0 5px;
      text-align: right;
      font-size: 18px;
      font-weight: 600;
    }
  }

  .fy {
    background: linear-gradient(0deg, #077ca0 0%, #4ccfd0 100%);
    width: 30px;
    height: 30px;
    .c {
      position: absolute;
      bottom: 2px;
      right: 2px;
      color: #ffffff;
      font-size: 12px;
      font-weight: 600;
    }
  }

  .center {
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 30%);

    top: 0;
    position: absolute;
    z-index: 10;
    .db_icon{
      position: absolute;
    }
    .bj {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 60px;
      height: 60px;
      background: rgba(0, 0, 0, 0.32);
      border-radius: 50%;
      /* margin: 0 auto; */
      transform: translate(-50%, -50%);
      color: #ffffff;
      text-align: center;
      line-height: 60px;
      font-size: 0.75rem;
      font-weight: 600;
    }
    .center-video {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 28px;
      height: 28px;
      background: url("../../assets-v2/images/Videoredpacket/shipin_bofang.png");
      border-radius: 50%;
      /* margin: 0 auto; */
      transform: translate(-50%, -50%);
      color: #ffffff;
      text-align: center;
      line-height: 60px;
      font-size: 0.75rem;
      font-weight: 600;
    }
    .center-text {
      text-align: center;
      position: absolute;
      top: 70%;
      left: 50%;
      width: 100%;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 600;
      color: #ffffff;
      transform: translate(-50%, -50%);
      background: rgba(255,0,0,0.8);
    }

    .noavaible {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100px;
      height: 100px;
      background: rgba(0, 0, 0, 0.32);
      border-radius: 50%;
      /* margin: 0 auto; */
      transform: translate(-50%, -50%);
      color: #ffffff;
      text-align: center;
      line-height: 100px;
      font-size: 1rem;
      font-weight: 600;
    }
  }

  .bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 32px;
    background: rgb(0 0 0 / 30%);

    border-radius: 0 0 4px 4px;
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    text-align: center;
    line-height: 32px;
  }
}

.attr {
  width: 220px;
  text-align: left;
  margin-left: 10px;
  justify-content: center;
  position: relative;
  .product-name {
    margin-top: 11px;
    text-overflow: ellipsis;
    font-size: 0.875rem;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    height: 20px;
    line-height: 20px;

    .jyb {
      display: inline-block;
      line-height: 18px;
      width: 18px;
      height: 18px;
      background: #3875f6;
      border-radius: 2px;
      color: #ffffff;
      text-align: center;
      font-size: 11px;
    }

    .guige {
      color: #666666;
      font-size: 12px;
    }
  }

  .margin11 {
    margin-top: 9px;
  }

  .act {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    .act-type {
      width: 40px;
      height: 18px;
      display: inline-block;
      color: #ffffff;
      font-size: 0.65rem;
      border-radius: 9px 0 9px 9px;
      text-align: center;
      line-height: 18px;
    }

    .act-mj {
      background: linear-gradient(0deg, #fe4b4f 0%, #fea8c1 100%);
    }

    .act-fhb {
      background: linear-gradient(0deg, #fc664c 0%, #fcb466 100%);
    }

    .act-name {
      font-size: 12px;
      margin-left: 5px;
      i {
        color: #e5432e;
      }
    }
  }

  .ybbm {
    font-size: 12px;
    font-weight: 400;
    height: 20px;
    line-height: 20px;
    display: flex;

    .bm {
      max-width: 180px;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .fz {
      color: #3875f6;
    }
  }

  .company,
  .yxq {
    color: #666666;
    font-weight: 400;
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .thsj {
    height: 20px;
    line-height: 20px;
    font-size: 0.875rem;
    font-weight: 400;
    color: #3875f6;
  }

  .syz {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 0.8rem;
    color: #666666;
  }

  .zbz,
  .ns-price {
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    font-weight: 400;
    color: #666666;
  }
}

.isControl{
  width: 45px;
  height: 45px;
  position: absolute;
  right: 20px;
  top: 10px;
}

.add-card-ball {
  width: 12px;
  height: 12px;
  background-color: red;
  border-radius: 100px;
  position: fixed;
  z-index: 99999;
}
.rgPs{
  color: #ffffff;
  background: linear-gradient( 90deg, #FFAAB8 0%, #FF004E 100%);
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  font-size: 12px;
  padding: 0 10px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 37;
  height: 20px;
  line-height: 20px;
}
.xsPs{
  background: linear-gradient( 90deg, #EB6F41 0%, #E03B29 100%);;
}
.isControlSale {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
  cursor: pointer;

  .isControlSale_btn {
    width: 120px;
    height: 24px;
    background: #3875F6;
    border-radius: 19px;
    opacity: 1;
    color: #ffffff;
    line-height: 24px;
    padding: 0 5px;
    text-align: center;
    font-size: 12px;
  }
}
.product-info_giveaway {
  position: absolute;
  bottom: 0;
  width: 90px;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 2px 0;
}
</style>

<script>
import mixin from "./mixin";
import qualificationsPopup from '@mixin/qualificationsPopup'
import productActivity from '@/components-v2/product/productActivity'
import Dialog from "../../components-v2/v2-dialog/index";
import VideoRedPacket from "./VideoRedPacket.vue";
import addCarModal from '../addCarModal/addCarModal.vue'
import qualificationsDialog from '@/components-v2/qualificationsDialog.vue'
import supplierRulerPop from "@/components-v2/supplierRulerPop/index.vue";
import lowestPriceForSimilar from '@/components-v2/lowestPriceForSimilar.vue'
import ProductActivityTags from '@/components-v2/ProductActivityTags.vue'
import ProductLabels from "@/components-v2/product/productLabels.vue";

export default {
  mixins: [mixin, qualificationsPopup, productActivity],
  components: {
    ProductActivityTags,
    ProductLabels,
    supplierRulerPop,
    lowestPriceForSimilar,
    qualificationsDialog,
    Dialog,
    VideoRedPacket,
    addCarModal
  },
  data(){
    return{}
  },
  methods: {

    //删除购物车某个产品
    async shopCardDel() {
      let $this = this;
      this.$confirm("确认是否将选中商品移出购物车？", "提示", {
        confirmButtonText: "删除",
        cancelButtonText: "取消"
      })
        .then(() => {
          const url = "/pjyy-deepexi-order-center/api/v1/cart/removeCartList";
          const param = {
            productId: [this.product.productId],
            userId: this.getUserId()
          };
          $this
            .$getHttpClient()
            .post(url, param)
            .then(result => {
              if (result.data.code == "200") {
                $this.$messageTip("删除成功！", "success");
                //刷新购物车
                this.findCardList().then(() => {
                  $this.freshShopCard();
                });
              }
            });
        })
        .catch(() => {
          // 失败
        });
    },

    //选择，取消选择,修改数量
    async shopCardCheck() {
      const url = "/pjyy-deepexi-order-center/api/v1/cart/findCartList";
      const param = {
        code: 2,
        productId: [this.product.productId],
        isChecked: this.product.isChecked ? 1 : 0
      };
      let r = await this.$getHttpClient().post(url, param);
      if (r.data.code == "200") {
        this.updateCard(r.data.data);
        this.freshShopCard();
      }
    },
    freshShopCard() {
      this.$emit("fresh");
    },
  }
};
</script>
